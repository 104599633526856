
/* latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url('./asset/Lato-Regular.ttf');
}
@font-face {
  font-family: 'Lato';
  font-style: bold;
  font-weight: 700;
  src: url('./asset/Lato-Bold.ttf');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont,Lato, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue','Lato',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
