.form {
    width: 100%;
    margin-top: 2rem;
    padding: 2rem 4rem;
}

@media screen and (min-width: 960px) {
    .form {
        max-width: 515px;
    }
}

@media screen and (max-width: 540px) {
    .form {
        padding: 2rem;
    }
}